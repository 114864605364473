import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// material-ui
import { Box, Button, Card, Grid, Input, Typography, TextareaAutosize, Switch } from '@mui/material';
import Select from "react-select";

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';

import { TCInput, TCSelect } from 'components/TopCourtComponents/Input/TopCourtInput';
import { getParamsFromURL } from 'helper/Params';
import { availableMonthOption, courtTypePackageOption, validityMonthOption } from "constant/constant"

import { createPackage, loadPackage, updatePackage } from 'services/packageServices';

import DropZoneComponent from 'components/DropzoneComponent';
import Loading from 'components/TopCourtComponents/Loading';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';

function AddPackage ({

}) {

  const [ form, setForm ] = useState({
    name: "",
    price: null,
    totalSession: null,
    advancedDays: null,
    advancedMonths: null,
    validity: null,
    description: "",
    court: null,
    maxPackages: null,
    isActive: true
  })
  
  const [joiForm, setJoiForm] = useState({
    name: null,
    price: null,
    totalSession: null,
    advancedMonths: null,
    validity: null,
    description: null,
    court: null,
    maxPackages: null
})

  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    let { isEdit, params } = getParamsFromURL(window);
    if (isEdit) { getPackageInfo(params) }
  }, [])

  useEffect(() => {
    setJoiForm({
      name: form.name? null : ' required',
      price: form.price>0? null : ' required',
      totalSession: form.totalSession>0? null : ' required',
      advancedMonths: form.advancedMonths? null : ' required',
      validity: form.validity? null : ' required',
      court: form.court? null : ' required',
      maxPackages: form.maxPackages>0? null : ' required'
    })
  }, [form])

  const disabledForm = () => {
    return joiForm.name || joiForm.price || joiForm.totalSession || joiForm.advancedMonths || joiForm.validity || joiForm.court || joiForm.maxPackages
}

  const onChange = (e) => {
    setForm(prev => ({
      ...prev,
      ...e
    }))
  }

  const getPackageInfo = async (packageId) => {
    setLoading(true)
    let { data, error } = await loadPackage(packageId)
    if (error) { console.log(error) }
    if (data) { 
      setForm(prev => ({
        ...prev,
        ...data,
        advancedMonths: data.advancedMonths ? availableMonthOption.map(v => { if(v.value === data.advancedMonths) return v }) : null,
        validity: data.validity ? validityMonthOption.map(v => { if(v.value === data.validity) return v }) : null,
        court: data.court ? courtTypePackageOption.map(v => { if(v.value === data.court) return v }) : null
      }))
    }
    setLoading(false)
  }

  const savePackage = async () => {
    setLoading(true)
    let newForm = {
      ...form,
      advancedMonths: form.advancedMonths ? form.advancedMonths.value : null,
      validity: form.validity ? form.validity.value : null,
      court: form.court ? form.court.value : null
    }
    if (form._id) {
      let { data, error } = await updatePackage(newForm)
      if (error) { console.log(error) }
      if (data) { window.location.href = "/packages" }
      setLoading(false)
    } else {
      let { data, error } = await createPackage(newForm)
      if (error) { console.log(error) }
      if (data) { window.location.href = "/packages" }
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <Loading/>}
      <Grid container xs={12} md={8} lg={6}>
        <Card sx={{ mb: 3 }}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                pb: 4.5,
              }}
            >
            <Grid container sx={{
                p: 2
            }}>
                <Grid item container xs={12} md={12} sx={{p: 1}}>
                  <Grid item xs>
                    <TCInput 
                        value={form.name}
                        showAlert={joiForm.name}
                        onChange={(e) => onChange({name: e.currentTarget.value.trimStart()})} label={"Name"}/>
                  </Grid>
                  <Grid item>
                    <TCLabel style={{ marginLeft: 10 }}>Status</TCLabel>
                    <Switch checked={form.isActive} onChange={() => onChange({ isActive: !form.isActive })} />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                <TCSelect label={'Court Type'} showAlert={joiForm.court} value={form.court}
                  onChange={(e) => {
                    console.log(e)
                    onChange({court: e})
                  }}
                  options={courtTypePackageOption}/>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                    <TCInput 
                      value={form.totalSession}
                      showAlert={joiForm.totalSession}
                      onChange={(e) => onChange({totalSession: e.currentTarget.value.replace(/[^0-9]+/ig, '')})} label={"Session"}/>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                    <TCInput 
                      value={form.price}
                      showAlert={joiForm.price}
                      onChange={(e) => onChange({price: e.currentTarget.value.replace(/[^0-9]+/ig, '')})} label={"Price"}/>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                <TCSelect label={'Advanced Booking'} value={form.advancedMonths} showAlert={joiForm.advancedMonths}
                  onChange={(e) => {
                    console.log(e)
                    onChange({advancedMonths: e})
                  }}
                  options={availableMonthOption}/>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                <TCSelect label={'Session Validity'} value={form.validity} showAlert={joiForm.validity}
                  onChange={(e) => {
                    onChange({validity: e})
                  }}
                  options={validityMonthOption}/>

                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                    {/* <TCInput styles={{height: 100}} type={'color'} label={"Package Color"}/> */}
                    <TCInput value={form.maxPackages} showAlert={joiForm.maxPackages} type={"number"} label={"Limit Global / Month"}
                     onInput={(e) => {
                      onChange({maxPackages: e.target.value.replace(/[^0-9]+/ig, '')})
                    }}/>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                    <TCLabel>Description</TCLabel>
                    {/* <TCInput type={"textarea"} rows={3} maxRows={5} onChange={(e) => onChange({description: e.currentTarget.value})} label={"Description"}/> */}
                    <ReactQuill theme="snow" value={form.description} onChange={(e) => onChange({description: e})} />
                </Grid>
            </Grid>
            <Grid container direction={"row"} sx={{
                pl: 3, pb: 3
            }}>
                <Button variant="contained" disabled={disabledForm()} onClick={() => {
                  savePackage()
                }}>
                  {form._id ? "Update" : "Add"}
                </Button>
            </Grid>         
            </Box>
          </Card>
      </Grid>
    </>
  )
}

export default AddPackage;
